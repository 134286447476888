import "./App.css"
import Logo from "./assets/Logo.png"
import LI from "./assets/LI.png"

function App() {

  return (
    <div className='container'>
      <div className='main'>
        <h1 className='main__title'>weBLACK.</h1>
        <img className='main__logo' src={Logo} alt='' />
        <p className='main__text'>
          Conscious social media putting people first
        </p>
        <a className='main__btn' href='#'>
          Coming Soon
        </a>
      </div>
      <div className='about'>
        <h1 className='about__header'>WE</h1>
        <p className='about__mission'>Our Mission</p>
        <p className='about__text'>
          weBLACK. seeks to address many of the problems members of the Black
          community experience on social media—from misrepresentation and bias
          to widespread false information—by providing a space specifically
          tailored to the Black community to empower Black voices and celebrate
          Black culture.
        </p>
        <h1 className='about__footer'>BLACK.</h1>
      </div>
      <div className='contact'>
        <h1 className='contact__txt'>Contact Us</h1>
        <div className='contact__links'>
          <a href='#' className='contact__links__link'>
            hello@weblack.org
          </a>
          <a href='#' className='contact__links__link'>
            <img src={LI} />
          </a>
        </div>
      </div>
      <div className='final'>
        <h1 className='final__header'>weBLACK.</h1>
        <p className='final__txt'>
          Conscious social media putting people first
        </p>
      </div>
    </div>
  )
}

export default App
